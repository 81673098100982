<template>
    <!-- Header Area -->

 	<header id="header-1" class="header-area" >
 		<div class="container">
 			<div class="row">
 				<div class="col-xl-2 col-lg-2 col-12">
 					<div class="logo">
 						<router-link :to="{name:'Home1'}" class="navbar-brand"><img src="/assets/img/logo.png" alt="pitron-logo"></router-link>
 					</div>
 				</div>

 				<div class="col-xl-2 col-lg-2 col-12">
 					<div class="sub-title">
 						<p>Law & Associates</p>
 					</div>
 				</div>

 				<div class="col-xl-8 col-lg-8 text-end">
 					<div id="header-aside">
 						<div class="aside-content">
 							<div class="inner">
 								<div class="info-one">
 									<div class="info-wrap">
 										<div class="info-i"><span><i class="las la-clock"></i></span></div>
 										<div class="info-c">Monday-Friday<br><span>08.00-20.00</span></div>
 									</div>
 								</div>
 								<div class="info-two">
 									<div class="info-wrap">
 										<div class="info-i"><span><i class="las la-map-marker"></i></span></div>
 										<div class="info-c">New York, USA<br><span>66, Broklyn St.</span></div>
 									</div>
 								</div>
 								<div class="info-three">
 									<div class="info-wrap">
 										<div class="info-i"><span><i class="las la-mobile"></i></span></div>
 										<div class="info-c">(+1)212-946-206<br><span>info@pitron.com</span></div>
 									</div>
 								</div>
 							</div>
 						</div>
 					</div>
 				</div>
 			</div>
 		</div>
 		<div class="container-fluid ">
 			<div class="sticky-area">
 				<div class="navigation sticky-top">
 					<div class="row">
 						<div class="col-lg-8">
 							<div class="main-menu">
 								<nav class="navbar navbar-expand-lg ">
 									<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
 										<span class="navbar-toggler-icon"></span>
 										<span class="navbar-toggler-icon"></span>
 										<span class="navbar-toggler-icon"></span>
 									</button>

 									<div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
 										<ul class="navbar-nav m-auto">
 											<li class="nav-item">
 												<a class="nav-link " href="#">Home
 													<span class="sub-nav-toggler">
 													</span>
 												</a>
 												<ul class="sub-menu">
 													<li><router-link class="router-link" :to="{name:'Home1'}">Home-01</router-link></li>
                          <li><router-link class="router-link" :to="{name:'Home2'}">Home-02</router-link></li>
                          <li><router-link class="router-link" :to="{name:'Home3'}">Home-03</router-link></li>
                          <li><router-link class="router-link" :to="{name:'Home4'}">Home-04</router-link></li>
                          <li><router-link class="router-link" :to="{name:'Home5'}">Home-05</router-link></li>
 												</ul>
 											</li>

 											<li class="nav-item">
 												<a class="nav-link" href="#">Pages
 													<span class="sub-nav-toggler">
 													</span>
 												</a>
 												<ul class="sub-menu">
 													<li><router-link :to="{name:'About'}">About Us</router-link></li>
 													<li><router-link :to="{name:'ChooseUs'}">Why Choose Us</router-link></li>
 													<li><router-link :to="{name:'Attorney'}">Our Attorney</router-link></li>
 													<li><router-link :to="{name:'AttorneyDetails', params:{ id: '1'} }">Attorney Details</router-link></li>
 													<li><router-link :to="{name:'Price'}">Pricing</router-link></li>
													<li><router-link :to="{name:'Services'}">Services</router-link></li>
 													<li><router-link :to="{name:'Faq'}">FAQ</router-link></li>
													 <li><router-link :to="{name:'Registration'}">Registration</router-link></li>
                                                    <li><router-link :to="{name:'SignIn'}">Sign In</router-link></li>
 												</ul>
 											</li>
 											<li class="nav-item">
 												<a class="nav-link" href="#">Practice Areas
 													<span class="sub-nav-toggler">
 													</span>
 												</a>
 												<ul class="sub-menu">
 													 <li><router-link :to="{name:'Services'}">Practice Areas</router-link></li>
                                                    <li><router-link :to="{name:'ServiceDetails', params:{ id: '1'} }">Practice Area Details</router-link></li>
 												</ul>

 											</li>

 											<li class="nav-item">
 												<a class="nav-link" href="#">Case Study
 													<span class="sub-nav-toggler">
 													</span>
 												</a>
 												<ul class="sub-menu">
 													<li><router-link :to="{name:'Case'}">Case Study</router-link></li>
 													<li><router-link :to="{name:'CaseDetails', params:{ id: '1'} }">Single Case Study</router-link></li>
 												</ul>
 											</li>

 											<li class="nav-item">
 												<a class="nav-link" href="#">Blog
 													<span class="sub-nav-toggler">
 													</span>
 												</a>
 												<ul class="sub-menu">
 													<li><router-link :to="{name:'Blog'}">Blog</router-link></li>
 													 <li><router-link :to="{name:'BlogDetails', params:{ id: '1' } }">Blog Details</router-link></li>
 												</ul>
 											</li>
 											<li class="nav-item">
 												<router-link class="nav-link" :to="{name:'Contact'}">Contact</router-link>
 											</li>
 										</ul>

 									</div>
 								</nav>
 							</div>
 						</div>
 						<div class="col-lg-4 text-center">
 							<div class="header-right-content">
								<router-link class="header-btn main-btn" :to="{name:'Contact'}">Free Consultation</router-link>
 							</div>
 						</div>
 						<!-- Responsive Logo-->
 						<div class="col-lg-4 col-md-4 col-sm-4">
 							<div class="responsive-logo">
 								<img src="/assets/img/logo-white.png" alt="Pitron-logo">
 							</div>
 						</div>
 					</div>
 				</div>
 			</div>
 		</div>
 	</header>
</template>

<script>
export default {
	mounted(){

		//jQuery Sticky Area
		$(".sticky-area").sticky({
		topSpacing: 0,
		});

		// Mobile Menu
        $(".navbar-toggler").on("click", function () {
            $(this).toggleClass("active");
        });

        $(".navbar-nav li a").on("click", function () {
            $(".sub-nav-toggler").removeClass("active");
        });

        var subMenu = $(".navbar-nav  .sub-menu");
        if (subMenu.length) {

            subMenu
                .parent("li")
                .children("a")
                .append(function () {
                    return '<button class="sub-nav-toggler"> <i class="las la-angle-down"></i> </button>';
                });

            var subMenuToggler = $(".navbar-nav .sub-nav-toggler");

            subMenuToggler.on("click", function () {
                $(this).parent().parent().children(".sub-menu").slideToggle();
                return false;
            });
        }

		// Menu Active Color

		$(".main-menu .navbar-nav .nav-link").on("mouseover", function () {
			$(".main-menu .navbar-nav .nav-link").removeClass("active");
			$(this).addClass("active");
		});


	},
    setup() {

    },
}
</script>

<style scoped>
.sticky-div {
    background-color: #10102D;
    position: sticky;
    top: 0px;
    padding: 10px 0px;
}
</style>

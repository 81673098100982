<template>
  <Navbar />
  <Breadcrumb mainTitle="Our Package" subTitle="Our Package" />
  <!--Pricing Section -->

  <div class="pricing-section section-padding">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="section-title text-center">
            <h6>Pricing</h6>
            <h2>
              Choose Your Affordable<b><br />Package</b>
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6" v-for="price in prices" :key="price.id">
          <div class="single-price-item wow fadeInLeft" data-wow-delay=".3s">
            <h5>{{ price.package_name }}</h5>
            <div class="price-box">
              <p>
                <b>${{ price.package_price }}</b>
              </p>
              <h6>{{ price.features[0].name }}</h6>
            </div>
            <div class="price-list">
              <ul>
                <li
                  v-for="feature in price.features.slice(
                    1,
                    price.features.length
                  )"
                  :key="feature.id"
                >
                  {{ feature.name }}
                </li>
                <!-- <li>Law Planning</li>
                                <li>Legal Advice</li>
                                <li>Hire Attorney</li> -->
              </ul>
            </div>
            <div class="main-btn small-btn">Choose Plan</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Footer from "@/components/Footer.vue";

import axios from "axios";

export default {
  name: "Price",
  components: { Navbar, Breadcrumb, Footer },
  data() {
    return {
      prices: [],
    };
  },

  async mounted() {
    //scrolling to top of the window
    window.scrollTo(0, 0);

    let fetchedData = await axios.get("/assets/data/prices.json");
    this.prices = fetchedData.data.prices;
  },
};
</script>
<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "Pricing | Pitron | Lawyers & Law Firm Vue Js Template",
  description: "Pricing page | Lawyers & Law Firm Vue Js Template",
  ogDescription: "Pricing page | Lawyers & Law Firm Vue Js Template",
  ogTitle: "Pricing | Pitron | Lawyers & Law Firm Vue Js Template",
});
</script>

<script setup>
import { onMounted } from "vue";
import Capsule from "./Capsule.vue";
onMounted(() => {
  //Progress Bar JS

  $("#bar1").barfiller({
    barColor: "#c89d66",
    duration: 5000,
  });

  $("#bar2").barfiller({
    barColor: "#c89d66",
    duration: 5000,
  });

  $("#bar3").barfiller({
    barColor: "#c89d66",
    duration: 5000,
  });

  $("#bar4").barfiller({
    barColor: "#c89d66",
    duration: 5000,
  });
});
</script>

<template>
  <div class="we-business-bg">
    <div class="container we-business-container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <!-- Section Title -->
          <div>
            <Capsule text="We Are Here" />
          </div>
          <div>
            <h1 class="we-business-title">
              We’re In The Business Of <br />
              Helping You
            </h1>
          </div>
          <div class="progress-bar-area we-business-bar-area">
            <div class="single-bar-item">
              <h4 class="we-business-bar-title">Transparency Services</h4>
              <div class="barfiller counter-number we-business-tip" id="bar1">
                <span class="tip">82%</span>
                <span class="fill" data-percentage="82"></span>
              </div>
            </div>
            <div class="single-bar-item">
              <h4 class="we-business-bar-title">Commitment & Responsibility</h4>
              <div class="barfiller counter-number we-business-tip" id="bar2">
                <span class="tip">95%</span>
                <span class="fill" data-percentage="95"></span>
              </div>
            </div>
            <div class="single-bar-item">
              <h4 class="we-business-bar-title">Professional Team</h4>
              <div class="barfiller counter-number we-business-tip" id="bar3">
                <span class="tip">85%</span>
                <span class="fill" data-percentage="85"></span>
              </div>
            </div>
            <div class="single-bar-item">
              <h4 class="we-business-bar-title">Relationship Advice</h4>
              <div class="barfiller counter-number we-business-tip" id="bar4">
                <span class="tip">90%</span>
                <span class="fill" data-percentage="90"></span>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* Customizing Bar Filler */
.tip {
  color: black;
  background-color: #fff0;
  font-size: 18px;
  font-family: "Rajdhani";
  color: rgb(47, 49, 64);
  font-weight: bold;
  line-height: 1.444;
  text-align: left;
}

.we-business-tip {
  margin-bottom: 35px;
}

.we-business-bar-title {
  font-size: 18px;
  font-family: "Rajdhani";
  color: rgb(47, 49, 64);
  font-weight: bold;
  line-height: 1.444;
  text-align: left;
  margin-bottom: 20px;
}

.we-business-bar-area {
  margin-top: 1.5rem;
  background-color: white;
  padding: 60px;
}

/* Styling Background */
.we-business-bg {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 825px;

  display: flex;
  align-items: center;
}

.we-business-container {
  background-color: #fff0;
}

/* Styling Content Area */
.we-business-title {
  font-size: 52px;
  font-family: "Rajdhani";
  color: rgb(47, 49, 64);
  font-weight: bold;
  line-height: 1.192;
  text-align: left;
}
</style>

<template>
  <router-view />
</template>

<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "Pitron | Lawyers & Law Firm Vue Js Template",
  description: "Lawyers & Law Firm Vue Js Template",
  ogDescription: "Lawyers & Law Firm Vue Js Template",
  ogTitle: "Pitron |Lawyers & Law Firm Vue Js Template",
});
</script>

<script setup>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination, A11y, Autoplay} from "swiper";
const modules = [Navigation, A11y, Pagination, Autoplay];
import Capsule from "./Capsule.vue";
import "swiper/css/pagination";

const pagination_settings = {
  clickable: true,
  el: ".practice-area-swiper-pagination",
  renderBullet: function (index, className) {
    return `<span class="${className} practice-area-bullet"></span>`;
  },
};
const breakPoints = {
  1200: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
};
</script>
<template>
  <div class="practice-area-bg-home4 practice-area-margin">
    <div class="container position-relative">
      <!-- Shape -->
      <div class="practice-area-shape">
        <img src="/assets/img/home4/shape_half_sphere.png" alt="" />
      </div>
      <!-- Section Title -->
      <div class="d-flex justify-content-center">
        <Capsule text="What We Do" />
      </div>
      <div>
        <h1 class="section-title-new">
          A Passion For Justice, Our<br />
          Practice Areas
        </h1>
      </div>
      <!-- Card Row -->
      <swiper :modules="modules" :pagination="pagination_settings" :breakpoints="breakPoints" navigation :loop="true"
        :autoplay="{delay: 4000}">
        <swiper-slide>
          <!-- Single Card Item -->
          <div class="single-practice-area-item position-relative">
            <div class="practice-area-img w-100">
              <img src="/assets/img/home4/what_we_do/wwd_card_1.png" class="img img-fluid w-100" />
            </div>
            <div class="position-absolute practice-area-icon d-none d-sm-block">
              <img src="/assets/img/home4/what_we_do/wwd_card_icon_1.png" />
            </div>
            <div class="practice-content w-100">
              <h5>Bussiness Law</h5>
              <p>
                Many desktop packages and web page editors now use Ipsum their
                default model text,
              </p>
              <div class="read-more">
                <router-link :to="{name: 'Blog'}">
                  <p>
                    <span>Read More </span> <i class="las la-arrow-right"></i>
                  </p>
                </router-link>
              </div>
              <div class="block-icon">
                <i class="las la-square"></i>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <!-- Single Card Item -->
          <div class="single-practice-area-item position-relative">
            <div class="practice-area-img w-100">
              <img src="/assets/img/home4/what_we_do/wwd_card_2.png" class="img img-fluid w-100" />
            </div>
            <div class="position-absolute practice-area-icon d-none d-sm-block">
              <img src="/assets/img/home4/what_we_do/wwd_card_icon_2.png" />
            </div>
            <div class="practice-content w-100">
              <h5>Family Law</h5>
              <p>
                Many desktop packages and web page editors now use Ipsum their
                default model text,
              </p>
              <div class="read-more">
                <router-link :to="{name: 'Blog'}">
                  <p>
                    <span>Read More </span> <i class="las la-arrow-right"></i>
                  </p>
                </router-link>
              </div>
              <div class="block-icon">
                <i class="las la-square"></i>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <!-- Single Card Item -->
          <div class="single-practice-area-item position-relative">
            <div class="practice-area-img w-100">
              <img src="/assets/img/home4/what_we_do/wwd_card_3.png" class="img img-fluid w-100" />
            </div>
            <div class="position-absolute practice-area-icon d-none d-sm-block">
              <img src="/assets/img/home4/what_we_do/wwd_card_icon_3.png" />
            </div>
            <div class="practice-content w-100">
              <h5>Criminal Law</h5>
              <p>
                Many desktop packages and web page editors now use Ipsum their
                default model text,
              </p>
              <div class="read-more">
                <router-link :to="{name: 'Blog'}">
                  <p>
                    <span>Read More </span> <i class="las la-arrow-right"></i>
                  </p>
                </router-link>
              </div>
              <div class="block-icon">
                <i class="las la-square"></i>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <!-- Single Card Item -->
          <div class="single-practice-area-item position-relative">
            <div class="practice-area-img w-100">
              <img src="/assets/img/home4/what_we_do/wwd_card_4.png" class="img img-fluid w-100" />
            </div>
            <div class="position-absolute practice-area-icon d-none d-sm-block">
              <img src="/assets/img/home4/what_we_do/wwd_card_icon_4.png" />
            </div>
            <div class="practice-content w-100">
              <h5>Real State Law</h5>
              <p>
                Many desktop packages and web page editors now use Ipsum their
                default model text,
              </p>
              <div class="read-more">
                <router-link :to="{name: 'Blog'}">
                  <p>
                    <span>Read More </span> <i class="las la-arrow-right"></i>
                  </p>
                </router-link>
              </div>
              <div class="block-icon">
                <i class="las la-square"></i>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="practice-area-swiper-pagination"></div>
      </swiper>
    </div>
  </div>
</template>
<style>
.section-title-new {
  font-size: 52px;
  color: rgb(47, 49, 64);
  font-weight: bold;
  line-height: 1.192;
  text-align: center;
}
</style>
<style>
/* Customizing Pagination Dots */
.practice-area-swiper-pagination {
  margin-top: 50px;
  min-height: 8px;
  width: 100% !important;
  text-align: center;
}

.swiper-pagination-bullet.practice-area-bullet.swiper-pagination-bullet {
  color: #c89d66 !important;
  background-color: #c89d66 !important;
  opacity: 1 !important;
  height: 8px;
  width: 8px;
}

.swiper-pagination-bullet.practice-area-bullet.swiper-pagination-bullet-active {
  color: #2f3140 !important;
  background-color: #2f3140 !important;
  height: 8px;
  width: 8px;
}
</style>

<style scoped>
.practice-area-margin {
  padding-top: 125px;
  padding-bottom: 125px;
}

.section-title {
  font-size: 52px;
  color: rgb(47, 49, 64);
  font-weight: bold;
  line-height: 1.192;
  text-align: center;
}

.practice-area-shape {
  position: absolute;
  left: -90px;
}

/* Styling Cards */
.practice-area-grid {
  grid-gap: 20px;
}

.single-practice-area-item {
  grid-column: auto / span 3;
}

.practice-content {
  background-color: #f7f2ec;
  padding: 10px 30px;
  padding-top: 70px;
  transition: background-color 0.4s;
}

.practice-content h5 {
  font-size: 26px;
  font-weight: 700;
}

.practice-content p {
  font-size: 18px;
  font-weight: medium;
}

.practice-area-icon {
  z-index: 2;
  left: 15px;
  top: 37%;
}

.read-more p {
  transform: translateX(-85px);
  transition: all 0.4s;
}

.read-more p span {
  visibility: hidden;
  transform: translateX(-2px);
  opacity: 0;
  transition: all 0.1s;
}

.single-practice-area-item:hover .practice-content {
  background-color: #0e1218;
}

.single-practice-area-item:hover .practice-content h5 {
  color: white;
}

.single-practice-area-item:hover .practice-content p {
  color: white;
}

.single-practice-area-item:hover .read-more p {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
}

.single-practice-area-item:hover .read-more p span {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
}

.single-practice-area-item:hover .read-more p {
  color: #c89d66;
}

.block-icon {
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 15px;
}

.single-practice-area-item:hover .block-icon {
  display: none;
}

/* **** End of Styling Cards **** */
@media screen and (max-width: 1200px) {
  .practice-area-icon {
    top: 54%;
  }
}

@media screen and (max-width: 992px) {
  .practice-area-icon {
    top: 44%;
  }
}

@media screen and (max-width: 768px) {
  .practice-area-icon {
    top: 58%;
  }
}
</style>

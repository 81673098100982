<script setup>
import axios from 'axios';
import {ref, onMounted} from 'vue';
import SingleResourceCard from "./SingleResourceCard.vue";
import Capsule from './Capsule.vue';

const resources = ref([])

onMounted(async () => {
  const {data: fetchedResources} = await axios.get('assets/data/resources_4.json');
  resources.value = fetchedResources;
})

</script>

<template>
  <div class="container we-live-margin">
    <!-- Section Title -->
    <div class="d-flex justify-content-center">
      <Capsule text="Our Resources" />
    </div>
    <div>
      <h1 class="section-title-new">We Live {{ "&" }} Work Globally</h1>
    </div>

    <div class="mt-5 d-grid-system we-live-grid">
      <div v-for="{id, count, unit, description} in resources" class="we-live-grid-item" :key="id">
        <SingleResourceCard :count="count" :unit="unit" :description="description" :key="id" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.we-live-margin {
  margin-top: 125px;
  margin-bottom: 125px;
}

.we-live-grid {
  grid-gap: 20px;
  grid-row: 180px;
}

.we-live-grid-item {
  grid-column: auto / span 3;
}

@media screen and (max-width:1200px) {
  .we-live-grid {
    grid-row: 180px 180px;
  }

  .we-live-grid-item {
    grid-column: auto / span 6;
  }

}

@media screen and (max-width:768px) {
  .we-live-grid-item {
    grid-column: auto / span 12;
  }

}
</style>

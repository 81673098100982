<template>
  <div class="footer4-bg">
    <!-- Footer -->
    <div class="container mb-5">
      <div class="d-grid-system footer4-grid">
        <div class="footer4-column">
          <div>
            <div class="w-100 m-0 p-0">
              <img class="img img-fluid m-0 p-0" src="/assets/img/logo-white.png" alt="">
            </div>
            <p class=" my-4">Duis aute irure dolor in cilum eu fugiat nulla pariatur. Excepteur non proident,
              sunt in
              culpa qui officia
              mollit</p>
            <div class="social-area">
              <p>FB</p>
              <p>TW</p>
              <p>LI</p>
              <p>BE</p>
            </div>
          </div>
        </div>
        <div class="footer4-column">
          <div class="ms-4">
            <h6 class="footer4-subtitle">Contact</h6>
            <!-- Single Contact Item -->
            <div class="mb-3">
              <div>
                <p class="m-0">
                  Flat 20, Reynolds Neck, North
                  Helenaville, FV77 8WS
                </p>
              </div>
            </div>
            <!-- Single Contact Item -->
            <div class="mb-3">
              <div>
                <p class="m-0 ">
                  Mon-Sat:10.00 am to 7.00pm
                </p>
              </div>
            </div>
            <!-- Single Contact Item -->
            <div class="mb-3">
              <div>
                <p class="m-0 ">
                  +33 4568 2488
                </p>
                <p class="m-0 ">
                  pirton@example.com
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="footer4-column">
          <div>
            <h6 class="footer4-subtitle">Company</h6>
            <div>
              <p>
                <router-link class="nav-link" :to="{name: 'About'}">About Us</router-link>
              </p>
              <p>
                <router-link class="nav-link" :to="{name: 'Attorney'}">Meet Our Team</router-link>
              </p>
              <p>
                <router-link class="nav-link" :to="{name: 'Blog'}">News & Media</router-link>
              </p>
              <p>
                <router-link class="nav-link" :to="{name: 'Case'}">Case Studies</router-link>
              </p>
              <p>
                <router-link class="nav-link" :to="{name: 'Contact'}">Contact</router-link>
              </p>
            </div>
          </div>
        </div>
        <div class="footer4-column">
          <div class="ms-4">
            <h6 class="footer4-subtitle">Gallery</h6>
            <div class="d-grid-system gallery-grid">
              <div class="gallery-item w-100">
                <img class="img img-fluid w-100" src="/assets/img/home4/footer/gallery_icon_1.png" alt="">
              </div>
              <div class="gallery-item w-100">
                <img class="img img-fluid w-100" src="/assets/img/home4/footer/gallery_icon_2.png" alt="">
              </div>
              <div class="gallery-item w-100">
                <img class="img img-fluid w-100" src="/assets/img/home4/footer/gallery_icon_3.png" alt="">
              </div>
              <div class="gallery-item w-100">
                <img class="img img-fluid w-100" src="/assets/img/home4/footer/gallery_icon_4.png" alt="">
              </div>
              <div class="gallery-item w-100">
                <img class="img img-fluid w-100" src="/assets/img/home4/footer/gallery_icon_5.png" alt="">
              </div>
              <div class="gallery-item w-100">
                <img class="img img-fluid w-100" src="/assets/img/home4/footer/gallery_icon_6.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* Styling Footer Part */
.footer4-bg {
  min-height: 300px;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: rgb(10, 15, 24);
  background-color: #191e25;
  background-image: url('../../../public/assets/img/home4/footer/home4_footer_bg.png')
}

.footer4-column p {
  font-size: 18px;
  font-family: "Rajdhani";
  color: rgb(149, 153, 158);
}

.footer4-grid {
  grid-gap: 5%;
}

.footer4-column {
  grid-column: auto / span 3;
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer4-column:not(:first-child) {
  border-left: 1px solid gray;
}

.footer-container {
  padding-top: 14rem;
}

.footer4-title {
  font-size: 52px;
  font-family: "Rajdhani";
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.192;
  text-align: left;

}

.footer4-subtitle {
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-size: 26px;
  font-family: "Rajdhani";
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.385;
  text-align: left;

}


.social-area {
  display: flex;
  justify-content: space-between;
  width: 50%;
  padding-left: 0;
}

.social-area p {
  margin-bottom: 0;
  text-decoration: underline;
}

.social-area p:hover {
  color: #c89d66;
  cursor: pointer;
}

/* Gallery Styling */
.gallery-grid {
  grid-gap: 10px;
}

.gallery-item {
  grid-column: auto / span 4;
}

@media screen and (max-width:992px) {

  .footer4-column {
    grid-column: auto / span 6;
  }

}

@media screen and (max-width:768px) {
  .footer4-column {
    grid-column: auto / span 12;
  }

  .footer4-bg {
    padding-bottom: 9rem;
    background-color: #13171c;
  }

}
</style>

<script setup>
import PracticeAreaCard from "../components/Home4/PracticeAreaCard.vue";
import Team4 from "@/components/Home4/Team4.vue";
import LatestNews from "../components/Home4/LatestNews.vue";
import NewsLetter from "../components/Home4/NewsLetter.vue";
import LetsGetStarted from "../components/Home4/LetsGetStarted.vue";
import WeLive from "../components/Home4/WeLive.vue";
import GetAPitron from "../components/Home4/GetAPitron.vue";
import AttorneysTrained from "../components/Home4/AttorneysTrained.vue";
import WeBusiness from "../components/Home4/WeBusiness.vue";
import ThinServicesBanner from "../components/Home4/ThinServicesBanner.vue";
import WhatClientSays from "../components/Home4/WhatClientSays.vue";
import Banner4 from "../components/Home4/Banner4.vue";
import NavbarHome4 from "../components/Home4/NavbarHome4.vue";
import ThinHeader4 from "../components/Home4/ThinHeader4.vue";
import Footer4 from "@/components/Home4/Footer4.vue";

import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "Home 4 | Pitron | Lawyers & Law Firm Vue Js Template",
  description: "Home page | Lawyers & Law Firm Vue Js Template",
  ogDescription: "Home page | Lawyers & Law Firm Vue Js Template",
  ogTitle: "Home 4 | Pitron | Lawyers & Law Firm Vue Js Template",
});
</script>

<template>
  <ThinHeader4 />
  <NavbarHome4 />
  <Banner4 />
  <PracticeAreaCard />
  <AttorneysTrained />
  <WeLive />
  <GetAPitron />
  <ThinServicesBanner />
  <WhatClientSays />
  <WeBusiness />
  <Team4 />
  <LetsGetStarted />
  <LatestNews />
  <div class="mt-150">
    <NewsLetter />
  </div>
  <Footer4 />
</template>

<script setup>
import {ref} from "vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination, A11y, Autoplay} from "swiper";
import Button from "./Button.vue";
import ControlTransfer from "./ControlTransfer.vue";

const modules = [Navigation, A11y, Autoplay];
const swiperControll = ref([]);
</script>
<template>
  <div class="row w-100 m-0" style="overflow: hidden">
    <div class="col-lg-1 d-none d-lg-block">
      <p class="text-center ps-5 pt-3 fs-3"><i class="las la-bars"></i></p>
      <div class="banner4-side-link-container">
        <p><i class="lab la-facebook"></i> Facebook</p>
        <p>|</p>
        <p><i class="lab la-twitter"></i> Twitter</p>
        <p>|</p>
        <p><i class="lab la-linkedin"></i> LinkedIn</p>
        <p>|</p>
        <p><i class="lab la-instagram"></i> Instagram</p>
      </div>
    </div>
    <div class="col-lg-11 p-0">
      <swiper :modules="modules" :slides-per-view="1" :space-between="0" navigation :pagination="{clickable: true}"
        :loop="true" :autoplay="{delay: 4000}">
        <swiper-slide>
          <div class="single-slide-item position-relative"
            style="background-image: url(/assets/img/home4/bannerBGpng.png)">
            <div class="hero-area-content">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-12 wow fadeInUp animated" data-wow-delay=".3s">
                    <div>
                      <h2 class="banner4-subtitle">Welcome to Pitron</h2>
                      <h1>
                        We are Certified <br />
                        Law <span class="stroke-text">Professionals</span>
                      </h1>
                      <p>
                        Law is the process of facilitating practicing, or
                        acquisition of knowledge, <br />
                        skills, values, beliefs, and habits.
                      </p>
                    </div>
                    <router-link :to="{name: 'About'}"><Button text="Discover More" /></router-link>
                    <router-link :to="{name: 'Contact'}" class="ms-2"><Button text="Contact with us"
                        variant="light" /></router-link>
                  </div>
                </div>
              </div>
            </div>
            <div @click="swiperControll.value.slideNext()" class="banner4-next-button-container d-none d-lg-flex">
              <div class="banner4-next-button banner4-next-button-1 d-flex align-items-end justify-content-center">
                <p>Next</p>
              </div>
              <div class="banner4-scroll-text">
                <p>Scroll</p>
              </div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="single-slide-item position-relative" style="
              background-image: url(/assets/img/home4/hero_get_in_touch.png);
            ">
            <div class="overlay-2"></div>
            <div class="hero-area-content">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-12 wow fadeInUp animated" data-wow-delay=".3s">
                    <div class="section-title-new">
                      <h1>
                        We are Certified <br />
                        Law <b>Professionals</b>
                      </h1>
                      <p>
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat on nulla pariatur.
                        Excepteur sint occaecat cupidatat
                      </p>
                    </div>
                    <router-link :to="{name: 'About'}" class="main-btn transparent">Learn More</router-link>
                    <router-link :to="{name: 'Case'}" class="main-btn">View Cases</router-link>
                  </div>
                </div>
              </div>
            </div>
            <div @click="swiperControll.value.slideNext()" class="banner4-next-button-container d-none d-lg-flex">
              <div class="banner4-next-button banner4-next-button-2 d-flex align-items-end justify-content-center">
                <p>Next</p>
              </div>
              <div class="banner4-scroll-text">
                <p>Scroll</p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <ControlTransfer :swiperControll="swiperControll" />
      </swiper>
    </div>
  </div>
</template>
<style scoped>
.stroke-text {
  color: black;
  -webkit-text-fill-color: black;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.banner4-subtitle {
  font-size: 30px;
  font-family: "Rajdhani";
  color: rgb(200, 157, 102);
  line-height: 0.867;
  text-align: left;
}

.banner4-side-link-container {
  overflow: hidden;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  text-orientation: sideways-right;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.banner4-side-link-container p {
  padding-right: 2rem;
  padding-top: 1rem;
  overflow: hidden;
  cursor: pointer;
}

.banner4-next-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  cursor: pointer;
}

.banner4-scroll-text {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  text-orientation: sideways-right;
  text-align: center;
  color: white;
  background-color: #c89d66;

  font-size: 20px;
  font-family: "Rajdhani";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2.3;
}

.banner4-next-button {
  color: white;
  width: 400px;
  height: 160px;
  background-size: cover;
  background-position: center;

  font-size: 16px;
  font-family: "Rajdhani";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2.875;
}

@media screen and (max-width: 768px) {
  .banner4-subtitle {
    text-align: center;
  }
}
</style>

<script setup>
import {ref} from "vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import ControlTransfer from "./ControlTransfer.vue";
import Button from "./Button.vue";
import {Navigation, Pagination, A11y, Autoplay} from "swiper";
import Capsule from "./Capsule.vue";
const modules = [Navigation, Pagination, A11y, Autoplay]
const swiperControll = ref([])
const breakpoints = {
  768: {
    slidesPerView: 2
  },
  200: {
    slidesPerView: 1
  }
}

</script>
<template>
  <div class="container testimonial-margin">
    <div class="row">
      <div class="col-xl-4 col-lg-12 testimonial-title-section">
        <div class="d-flex justify-content-center w-100 d-xl-block">
          <div>
            <Capsule text="our testimonials" />
          </div>
        </div>
        <h1 class="testimonial-title">What Our Client <br />
          Say About Pitron
        </h1>
        <div class="mt-2">
          <button @click="swiperControll.value.slidePrev()" class="testimonial-control-button">
            <i class="las la-arrow-left"></i>
          </button>
          <button @click="swiperControll.value.slideNext()" class="testimonial-control-button ms-2">
            <i class="las la-arrow-right"></i>
          </button>
        </div>
      </div>
      <div class="col-xl-8 col-lg-12">
        <swiper :modules="modules" :loop="true" :space-between="20" class="w-100" :breakpoints="breakpoints">
          <swiper-slide>
            <div class="testimonial-slide-bg">
              <div style="color:#c89d66;">
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
              </div>
              <p class="my-3">Many desktop publishing packages and web page editors Lorem Ipsum as their default model
                text, and a
                search
                will er many web sites still in their infancy.
              </p>
              <div class="d-flex justify-content-between">
                <div class="testimonial-author-img-container">
                  <img src="/assets/img/home4/what_client_says/testimonial_author_1.png" alt="" />
                  <div class="ms-2">
                    <h3 class="testimonial-author-title">Nicolas Otamendi</h3>
                    <p class="m-0 testimonial-author-role">Social Activist</p>
                  </div>
                </div>
                <div class="testimonial-quote-icon">
                  <img src="/assets/img/home4/what_client_says/quote.png" alt="" />
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="testimonial-slide-bg">
              <div style="color:#c89d66;">
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
              </div>
              <p class="my-3">Many desktop publishing packages and web page editors Lorem Ipsum as their default model
                text, and a
                search
                will er many web sites still in their infancy.
              </p>
              <div class="d-flex justify-content-between">
                <div class="testimonial-author-img-container">
                  <img src="/assets/img/home4/what_client_says/testimonial_author_2.png" alt="" />
                  <div class="ms-2">
                    <h3 class="testimonial-author-title">Nicolas Otamendi</h3>
                    <p class="m-0 testimonial-author-role">Social Activist</p>
                  </div>
                </div>
                <div class="testimonial-quote-icon">
                  <img src="/assets/img/home4/what_client_says/quote.png" alt="" />
                </div>
              </div>
            </div>
            <ControlTransfer :swiperControll="swiperControll" />
          </swiper-slide>
        </swiper>

      </div>
    </div>
  </div>
</template>
<style scoped>
.testimonial-margin {
  margin-top: 125px;
  margin-bottom: 125px;
}

.testimonial-title {
  font-size: 52px;
  font-family: "Rajdhani";
  color: rgb(47, 49, 64);
  font-weight: bold;
  line-height: 1.192;
  text-align: left;

}

.testimonial-control-button {
  border: none;
  border-radius: 50%;
  padding: 0.85rem;
  text-align: center;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  background-color: #c89d66;
  transition: all 0.4s;
}

.testimonial-control-button:hover {
  background-color: #0e1218;
}

.testimonial-slide-bg {
  background-color: #f7f2ec;
  padding: 40px;
}

.testimonial-author-img-container {
  display: flex;
  align-items: center;
}

.testimonial-author-title {
  font-size: 20px;
  font-family: "Rajdhani";
  color: rgb(47, 49, 64);
  font-weight: bold;
  line-height: 1.4;

}

.testimonial-author-role {
  font-size: 18px;
  font-family: "Rajdhani";
  color: rgb(79, 79, 103);
  line-height: 1.556;
}

@media screen and (max-width:1200px) {

  .testimonial-title,
  .testimonial-subtitle {
    text-align: center;
  }

  .testimonial-title-section {
    margin-bottom: 4rem;
    text-align: center;
  }

}
</style>

<template>
  <Navbar />
  <!-- Hero Area -->

  <div id="home-2" class="homepage-slides">
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :space-between="0"
      navigation
      :pagination="{ clickable: true }"
      :autoplay="{ delay: 4000 }"
    >
      <swiper-slide>
        <div class="single-slide-item hero-area-bg-3 bg-cover">
          <div class="overlay"></div>
          <div class="hero-area-content">
            <div class="container">
              <div class="row justify-content-center">
                <div
                  class="col-lg-12 wow fadeInUp animated"
                  data-wow-delay=".3s"
                >
                  <div class="section-title">
                    <h1>
                      We Always Stand <br />
                      Right <b>justice</b>
                    </h1>
                    <p>
                      Law is the process of facilitating practicing, or
                      acquisition of knowledge, <br />
                      skills, values, beliefs, and habits.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="single-slide-item hero-area-bg-4">
          <div class="overlay"></div>
          <div class="hero-area-content">
            <div class="container">
              <div class="row justify-content-center">
                <div
                  class="col-lg-12 wow fadeInUp animated"
                  data-wow-delay=".3s"
                >
                  <div class="section-title">
                    <h1>
                      We are Certified <br />
                      Law <b>Professionals</b>
                    </h1>
                    <p>
                      Law is the process of facilitating practicing, or
                      acquisition of knowledge, <br />
                      skills, values, beliefs, and habits.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>

  <!-- Info Secton -->
  <div class="info-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-12">
          <div class="info-inner wow fadeInUp" data-wow-delay=".3s">
            <div class="row gx-0">
              <div class="col-lg-4 col-md-12 col-12">
                <div class="info-bg bg-cover"></div>
              </div>
              <div class="col-lg-8 col-md-12 col-12">
                <div class="info-content">
                  <h5>
                    Pitron is your ultimate legal partner you can <b>trust</b>
                  </h5>
                  <router-link :to="{ name: 'Services' }">
                    All Services</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-12 wow fadeInUp" data-wow-delay=".4s">
          <div class="info-founder">
            <i class="flaticon-gavel"></i>
            <p class="highlight">25+</p>
            <p>Years <br />Experience</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Services Area -->

  <div id="service-2" class="services-area section-padding pb-50">
    <div class="container">
      <div class="row">
        <div class="offset-lg-2 col-lg-8 text-center">
          <div class="section-title">
            <h6>Services</h6>
            <h2>
              What we are offering<b
                ><br />
                to solve cases</b
              >
            </h2>
            <p>
              Combining Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Repellendus laboriosam omnis cum, natus doloremque
              perferendis.
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft"
          data-wow-delay=".2s"
          v-for="(service, i) in services.slice(0, 6)"
          :key="service.id"
        >
          <div
            class="single-service-area mb-50"
            :class="
              i % 2 ? 'service-' + service.id : 'blue-bg service-' + service.id
            "
          >
            <div class="service-icon">
              <i :class="service.icon"></i>
            </div>
            <h4>{{ service.name }}</h4>
            <p>{{ service.short_description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- About Section-->

  <div id="about-2" class="about-area section-padding">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-lg-5 wow fadeInUp" data-wow-delay=".3s">
          <div class="mt-10"></div>
          <div class="about-bg-wrapper">
            <div class="about-bg-innner bg-cover"></div>
          </div>
        </div>
        <div class="col-lg-7 wow fadeInDown" data-wow-delay=".4s">
          <div class="about-content-wrapper">
            <div class="section-title">
              <h6>About Us</h6>
              <h2>We are the Leading Law Firm in <b>worldwide</b></h2>
            </div>
            <p>
              Logistics ennovation for freight plan Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Quos natus ipsam ea vero placeat?
              Quia consequuntur odio nemo incidunt, perferendis.
            </p>
            <div class="row">
              <div
                class="col-lg-6 col-md-6 col-12"
                v-for="feature in about.features"
                :key="feature.id"
              >
                <div class="key-feature">
                  <div class="row no-gutters">
                    <div class="col-lg-4">
                      <div class="about-icon">
                        <img :src="feature.icon" alt="" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h4>{{ feature.title }}</h4>
                      <p>{{ feature.short_description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row d-flex pt-30">
              <div class="img-content">
                <img class="img-fluid" :src="about.admin_sign" alt="sign" />
              </div>
              <div class="text-content">
                <h6>- {{ about.admin_name }}</h6>
                <p>{{ about.admin_designation }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Case Studies -->

  <div class="case-area sky-bg section-padding">
    <div class="container">
      <div class="offset-lg-1 col-lg-10 text-center">
        <div class="section-title">
          <h6>Featured Work</h6>
          <h2>Recent <b>Case Studies</b></h2>
          <p>
            Law Practice equipment modern technique sit amet, consectetur
            adipisicing elit. <br />
            Repellendus laboriosam omnis cum.
          </p>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-12 wow fadeInLeft"
          data-wow-delay=".2s"
          v-for="c in cases.slice(0, 6)"
          :key="c.id"
        >
          <div
            class="single-case-item mb-50 bg-cover"
            :style="'background-image:url(' + c.images.bg_image + ')'"
          >
            <div class="case-inner">
              <div class="hover-info">
                <h6>{{ c.category }}</h6>
                <h4>{{ c.short_title }}</h4>
              </div>
              <router-link
                class="case-icon"
                :to="{ name: 'CaseDetails', params: { id: c.id } }"
              >
                <i class="las la-plus"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Process Area -->

  <div class="featured-process-area">
    <div class="container"></div>
    <div class="content-area">
      <div class="process-grids bg-cover clearfix">
        <div class="overlay-2"></div>
        <div
          class="grid wow fadeInUp"
          data-wow-delay=".2s"
          v-for="process in choose_us.processes"
          :key="process.id"
        >
          <div class="inner">
            <span class="count">0{{ process.id }}.</span>
            <h3>{{ process.title }}</h3>
            <p>{{ process.short_description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Choose Us Area -->

  <div class="choose-us-area gray-bg section-padding">
    <div class="container">
      <div class="row">
        <div class="offset-lg-2 col-lg-8 text-center">
          <div class="section-title">
            <h6>Why Choose Us</h6>
            <h2>We offer perfect real <br /><b>Legal Service</b></h2>
          </div>
        </div>
      </div>
      <div class="choose-us-wrapper wow fadeInUp" data-wow-delay=".3s">
        <div class="row no-gutters">
          <div
            class="col-lg-3 col-md-6 col-12"
            v-for="feature in choose_us.key_features"
            :key="feature.id"
          >
            <div class="choose-us-inner">
              <div class="choose-us-icon">
                <img :src="feature.icon" alt="" />
              </div>
              <h5>{{ feature.title }}</h5>
              <p>{{ feature.short_description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="spacer section-padding"></div>

  <!-- Testimonial Area -->

  <TestimonialTwo />

  <!-- Blog Section-->

  <div class="blog-area gray-bg section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="section-title">
            <h6>Blog</h6>
            <h2>Latest Law <b>Articles</b></h2>
          </div>
        </div>

        <div class="col-lg-6 text-right"></div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft"
          data-wow-delay=".4s"
          v-for="blog in blogs.slice(0, 3)"
          :key="blog.id"
        >
          <div class="single-blog-item">
            <div class="blog-bg">
              <img :src="blog.images.banner_image" :alt="blog.title" />
            </div>
            <div class="blog-content">
              <p class="blog-meta">
                <i class="las la-user-circle"></i>Admin |
                <i class="las la-calendar-check"></i>25 Feb
              </p>
              <h5>
                <router-link
                  :to="{ name: 'BlogDetails', params: { id: blog.id } }"
                  >{{ blog.title }}</router-link
                >
              </h5>
              <p>{{ blog.short_description.substr(0, 40) }}...</p>
              <router-link
                :to="{ name: 'BlogDetails', params: { id: blog.id } }"
                class="read-more"
                >Read More</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script>
import axios from "axios";

// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import TestimonialTwo from "@/components/TestimonialTwo.vue";
import Footer from "@/components/Footer.vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

export default {
  name: "Home2",
  components: { Navbar, Swiper, SwiperSlide, TestimonialTwo, Footer },
  data() {
    return {
      modules: [Navigation, Pagination, A11y, Autoplay],
      swiperOptions: {
        breakpoints: {
          354: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          575: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          767: {
            slidesPerView: 2,
            spaceBetween: 50,
          },

          990: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        },
      },
      blogs: [],
      choose_us: [],
      cases: [],
      about: [],
      services: [],
    };
  },
  async mounted() {
    //scrolling to top of the window
    window.scrollTo(0, 0);

    let fetchedBlogData = await axios.get("/assets/data/blogs.json");
    this.blogs = fetchedBlogData.data.blogs;

    let fetchedData = await axios.get("/assets/data/choose_us.json");
    this.choose_us = fetchedData.data.choose_us;

    let fetchedCases = await axios.get("/assets/data/cases.json");
    this.cases = fetchedCases.data.cases;

    let fetchedaboutData = await axios.get("/assets/data/about_us.json");
    this.about = fetchedaboutData.data.about_us;

    let fetchedServiceData = await axios.get("/assets/data/services.json");
    this.services = fetchedServiceData.data.services;
  },
};
</script>
<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "Home 2 | Pitron | Lawyers & Law Firm Vue Js Template",
  description: "Home page | Lawyers & Law Firm Vue Js Template",
  ogDescription: "Home page | Lawyers & Law Firm Vue Js Template",
  ogTitle: "Home 2 | Pitron | Lawyers & Law Firm Vue Js Template",
});
</script>

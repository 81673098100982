<script setup>

</script>
<template>
  <div class="container-fluid p-0 thin-header4-bg d-none d-xl-block">
    <div class="d-grid-system">
      <div class="left-side">
        <div>
          <p>Mon - Fri : 09:00 - 17:00</p>
        </div>
        <div>
          <p>info@example.com</p>
        </div>
        <div>
          <p>No: 58 A, East Madison Street, Baltimore, MD, USA 4508</p>
        </div>
      </div>
      <div class="right-side">
        <div class="right-side-item-container">
          <div class="triangle-shape">
          </div>
          <div>
            <p>About Us</p>
          </div>
          <div>
            <p>Frequently Asked Question</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>

  </div>
</template>

<style scoped>
.thin-header4-bg {
  background-color: #0e1218;
}

.left-side {
  margin: 0;
  padding-left: 2rem;
  width: 75%;
  background-color: #0e1218;
  grid-column: auto / span 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.triangle-shape {
  height: 50px;
  width: 30px;
  border: none;
  padding: 0;
  margin: 0;
  background-color: #0e1218;
  -webkit-clip-path: polygon(0 0, 100% 50%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 50%, 100% 50%, 0 100%);
}

.right-side {
  background-color: #c89d66;
  margin: 0;
  grid-column: auto / span 3;
}

.right-side-item-container {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
}

.left-side p,
.right-side p {
  color: white;
  margin-bottom: 0;
  font-size: 16px;
}
</style>

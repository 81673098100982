<template>
  <!-- Header Top Area -->

  <div class="header-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="contact-info">
            <i class="las la-envelope"></i> info@pitron.com |
            <i class="las la-map-marker"></i> 66, Broklyn St, New York, USA
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 text-end">
          <div class="site-info">
            Opening Hours: Monday-Saturday - 08.00 a.m. to 5.00 p.m.
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Header Area -->

  <header id="header-2" class="header-area absolute-header pt-50">
    <div class="sticky-area">
      <div class="navigation">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 col-12">
              <div class="logo">
                <router-link :to="{ name: 'Home2' }" class="navbar-brand"
                  ><img src="/assets/img/logo-white.png" alt=""
                /></router-link>
              </div>
            </div>

            <div class="col-lg-6 col-12">
              <div class="main-menu">
                <nav class="navbar navbar-expand-lg">
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                  </button>

                  <div
                    class="collapse navbar-collapse justify-content-center"
                    id="navbarSupportedContent"
                  >
                    <ul class="navbar-nav m-auto">
                      <li class="nav-item">
                        <a class="nav-link" href="#"
                          >Home
                          <span class="sub-nav-toggler"> </span>
                        </a>
                        <ul class="sub-menu">
                          <li>
                            <router-link :to="{ name: 'Home1' }"
                              >Home-01</router-link
                            >
                          </li>
                          <li>
                            <router-link :to="{ name: 'Home2' }"
                              >Home-02</router-link
                            >
                          </li>
                          <li>
                            <router-link :to="{ name: 'Home3' }"
                              >Home-03</router-link
                            >
                          </li>
                          <li>
                            <router-link :to="{ name: 'Home4' }"
                              >Home-04</router-link
                            >
                          </li>
                          <li>
                            <router-link :to="{ name: 'Home5' }"
                              >Home-05</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#"
                          >Pages
                          <span class="sub-nav-toggler"> </span>
                        </a>
                        <ul class="sub-menu">
                          <li>
                            <router-link :to="{ name: 'About' }"
                              >About Us</router-link
                            >
                          </li>
                          <li>
                            <router-link :to="{ name: 'ChooseUs' }"
                              >Why Choose Us</router-link
                            >
                          </li>
                          <li>
                            <router-link :to="{ name: 'Attorney' }"
                              >Our Attorney</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'AttorneyDetails',
                                params: { id: '1' },
                              }"
                              >Attorney Details</router-link
                            >
                          </li>
                          <li>
                            <router-link :to="{ name: 'Price' }"
                              >Pricing</router-link
                            >
                          </li>

                          <li>
                            <router-link :to="{ name: 'Faq' }">FAQ</router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'Registration' }"
                              >Registration</router-link
                            >
                          </li>
                          <li>
                            <router-link :to="{ name: 'SignIn' }"
                              >Sign In</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#"
                          >Practice Areas
                          <span class="sub-nav-toggler"> </span>
                        </a>
                        <ul class="sub-menu">
                          <li>
                            <router-link :to="{ name: 'Services' }"
                              >Practice Areas</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'ServiceDetails',
                                params: { id: '1' },
                              }"
                              >Practice Area Details</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#"
                          >Case Study
                          <span class="sub-nav-toggler"> </span>
                        </a>
                        <ul class="sub-menu">
                          <li>
                            <router-link :to="{ name: 'Case' }"
                              >Case Study</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{ name: 'CaseDetails', params: { id: '1' } }"
                              >Single Case Study</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#"
                          >Blog
                          <span class="sub-nav-toggler"> </span>
                        </a>
                        <ul class="sub-menu">
                          <li>
                            <router-link :to="{ name: 'Blog' }"
                              >Blog</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{ name: 'BlogDetails', params: { id: '1' } }"
                              >Blog Details</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'Contact' }"
                          >Contact</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div class="col-lg-3 col-12 text-end">
              <div class="header-right-content">
                <router-link class="main-btn" :to="{ name: 'Contact' }"
                  >Free Consultation</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  mounted() {
    //jQuery Sticky Area
    $(".sticky-area").sticky({
      topSpacing: 0,
    });

    // Mobile Menu
    $(".navbar-toggler").on("click", function () {
      $(this).toggleClass("active");
    });

    $(".navbar-nav li a").on("click", function () {
      $(".sub-nav-toggler").removeClass("active");
    });

    var subMenu = $(".navbar-nav  .sub-menu");
    if (subMenu.length) {
      subMenu
        .parent("li")
        .children("a")
        .append(function () {
          return '<button class="sub-nav-toggler"> <i class="las la-angle-down"></i> </button>';
        });

      var subMenuToggler = $(".navbar-nav .sub-nav-toggler");

      subMenuToggler.on("click", function () {
        $(this).parent().parent().children(".sub-menu").slideToggle();
        return false;
      });
    }

    // Menu Active Color

    $(".main-menu .navbar-nav .nav-link").on("mouseover", function () {
      $(".main-menu .navbar-nav .nav-link").removeClass("active");
      $(this).addClass("active");
    });
  },
  setup() {},
};
</script>

<style scoped>
.sticky-div {
  background-color: #10102d;
  position: sticky;
  top: 0px;
  padding: 10px 0px;
}
</style>

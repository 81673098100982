<template>
  <NavbarHome3 />
  <!-- Hero Area -->

  <div id="home-3" class="homepage-slides">
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :space-between="30"
      navigation
      :pagination="{ clickable: true }"
      :autoplay="{ delay: 4000 }"
    >
      <swiper-slide>
        <div class="single-slide-item hero-area-bg-5">
          <div class="overlay-2"></div>
          <div class="hero-area-content">
            <div class="container">
              <div class="row justify-content-center">
                <div
                  class="col-lg-8 col-md-10 col-11 text-center wow fadeInUp animated"
                  data-wow-delay=".2s"
                >
                  <div class="section-title">
                    <h6>Service since 1995</h6>
                    <h1>We are Pitron Law & <b>Associates</b></h1>
                    <p>
                      We are the populer Law Firm with various Law Services. We
                      can service all of legal advice from foundation to ending,
                      hearing and case dismissed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="single-slide-item hero-area-bg-6">
          <div class="overlay-2"></div>
          <div class="hero-area-content">
            <div class="container">
              <div class="row justify-content-center">
                <div
                  class="col-lg-8 col-md-10 col-11 text-center wow fadeInUp animated"
                  data-wow-delay=".2s"
                >
                  <div class="section-title">
                    <h6>Service since 1995</h6>
                    <h1>We're Professional in <b>Legal Services</b></h1>
                    <p>
                      We are the populer Law Firm with various Law Services. We
                      can service all of legal advice from foundation to ending,
                      hearing and case dismissed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>

  <!-- Promo Area -->

  <div class="promo-area">
    <div class="container">
      <div class="row gx-0">
        <div class="col-lg-4 col-12">
          <div class="single-promo-item mb-50">
            <div class="promo-icon">
              <i class="flaticon-lawyer"></i>
            </div>
            <h5>Qualified Attorneys</h5>
            <p>
              Law is main lorem ipsum dolor sit amet, consectetur adipisicing
              elit.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div class="single-promo-item mb-50 spro">
            <div class="promo-icon">
              <i class="flaticon-courthouse"></i>
            </div>
            <h5>Court Performance</h5>
            <p>
              Law is main lorem ipsum dolor sit amet, consectetur adipisicing
              elit.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div class="single-promo-item mb-50">
            <div class="promo-icon">
              <i class="flaticon-podium"></i>
            </div>
            <h5>90% Success Rate</h5>
            <p>
              Law is main lorem ipsum dolor sit amet, consectetur adipisicing
              elit.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- About Us Area-->

  <div id="about-3" class="about-area bg-cover section-padding">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 col-md-12 wow fadeInLeft" data-wow-delay=".3s">
          <div class="about-content-area">
            <div class="section-title">
              <h6>A little Bit More</h6>
              <h2>
                25+ Years of Experience in Law Service that need of our
                <b>Trusted Clients</b>
              </h2>
            </div>

            <p>
              We are fight in law against injustice ipsum dolor sit amet,
              consectetur adipisicing elit. Ea enim et, similique, minus soluta
              ducimus. Lance was murder on the Island of Occidental Mindoro, one
              of many islands in the Philippines. His home town of Santa Cruz is
              lucky enough.
            </p>

            <ul class="about-key mt-30">
              <li>
                <i class="las la-check-square"></i>
                <p>Qualified Attorneys</p>
              </li>
              <li>
                <i class="las la-check-square"></i>
                <p>25 Years of Experience</p>
              </li>
              <li>
                <i class="las la-check-square"></i>
                <p>Leading Law Firm</p>
              </li>
              <li>
                <i class="las la-check-square"></i>
                <p>Highest Success Rate</p>
              </li>
            </ul>
            <div class="about-contact">
              <div class="row">
                <div class="col-lg-6">
                  <div class="call-us d-flex mt-30">
                    <div class="icon"><i class="las la-phone"></i></div>
                    <div class="mobile-number">
                      <h5>Free Case Evaluation</h5>
                      <p>92 666 888 0000</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <a href="/about" class="main-btn">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 wow fadeInRight" data-wow-delay=".4s">
          <div class="about-bg">
            <img src="assets/img/about/about-3.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Service Area -->

  <div id="service-3" class="serv-area sky-bg section-padding">
    <div class="container">
      <div class="row">
        <div class="offset-lg-2 col-lg-8 text-center">
          <div class="section-title">
            <h6>What We do</h6>
            <h2>We Offer Perfect Real<br /><b>Law Service</b></h2>
            <p>
              We can service all of legal procedure from starting to ending,
              hearing, impitchment & witness. We have done adequetly something.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-6 col-md-6 col-sm-12 wow fadeInLeft"
          data-wow-delay=".2s"
          v-for="service in services.slice(0, 4)"
          :key="service.id"
        >
          <div class="single-serv-wrapper">
            <div class="row gx-0">
              <div class="col-lg-6">
                <div
                  class="single-serv-bg bg-cover"
                  :style="
                    'background-image:url(' + service.images.banner_image + ')'
                  "
                ></div>
              </div>
              <div class="col-lg-6">
                <div class="single-serv-area">
                  <div class="service-icon">
                    <i :class="service.icon"></i>
                  </div>
                  <h5>{{ service.name }}</h5>
                  <p>{{ service.short_description }}</p>
                  <router-link
                    class="read-more"
                    :to="{ name: 'ServiceDetails', params: { id: service.id } }"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Case Area -->

  <div id="case-3" class="case-area section-padding">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-title text-center">
            <h6>Recent Case Solved</h6>
            <h2>We bring justification againts <b>injustice</b></h2>
          </div>
        </div>
      </div>
      <div class="case-slider">
        <!-- <div class="row"> -->
        <swiper
          :modules="modules"
          :slides-per-view="1"
          :space-between="30"
          :autoplay="{ delay: 4000 }"
          :breakpoints="{
            '575': {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            '768': {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            '990': {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            '1024': {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }"
        >
          <swiper-slide v-for="singleCase in allCases" :key="singleCase.id">
            <!-- <div class="col-lg-4 col-md-6 col-12 wow fadeInLeft" data-wow-delay=".2s"> -->
            <div
              class="single-case-item mb-50 bg-cover"
              :style="
                'background-image:url(' + singleCase.images.bg_image + ')'
              "
            >
              <div class="case-inner">
                <div class="hover-info">
                  <h4>{{ singleCase.short_title }}</h4>
                  <p>{{ singleCase.short_description }}</p>
                  <router-link
                    class="case-icon"
                    :to="{ name: 'CaseDetails', params: { id: singleCase.id } }"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
            <!-- </div> -->
          </swiper-slide>
        </swiper>
        <!-- </div> -->
      </div>
    </div>
  </div>

  <!-- Achievement Area -->

  <div class="skill-area">
    <div class="skill-bg bg-cover">
      <div class="overlay"></div>
      <div class="pop-up-video">
        <a
          href="https://www.youtube.com/watch?v=RQu7jpcNUWI"
          class="video-play-btn mfp-iframe"
        >
          <i class="fa fa-play"></i>
        </a>
      </div>
    </div>
    <div class="skill-content">
      <div class="section-title mb-30">
        <h6>Our Achievement</h6>
        <h2>
          Our Brilliant Skill & <b><br />Creativity</b>
        </h2>
      </div>
      <p>
        There are planty industrial solution the filed. One of the best civil
        work in downtown have done by expert contractor.
      </p>
      <div class="row no-gutters mt-40">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="single-counter-box one">
            <div class="fact-icon">
              <i class="flaticon-book"></i>
            </div>
            <p class="counter-number">
              <span>{{ achivement.case_dismissed }}</span>
            </p>
            <h6>Case Dismissed</h6>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="single-counter-box two">
            <div class="fact-icon">
              <i class="flaticon-attorney"></i>
            </div>
            <p class="counter-number">
              <span>{{ achivement.expert_lawyer }}</span>
            </p>
            <h6>Expert Lawyer</h6>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="single-counter-box three">
            <div class="fact-icon">
              <i class="flaticon-support"></i>
            </div>
            <p class="counter-number">
              <span>{{ achivement.trusted_client }}</span
              >+
            </p>
            <h6>Trusted Client</h6>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="single-counter-box four">
            <div class="fact-icon">
              <i class="flaticon-insurance"></i>
            </div>
            <p class="counter-number">
              <span>{{ achivement.minimal_rate }}</span
              >+
            </p>
            <h6>Minimal Rate</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Process Section -->

  <div class="process-area gray-bg section-padding pb-50">
    <div class="container">
      <div class="row">
        <div class="offset-lg-2 col-lg-8 text-center">
          <div class="section-title">
            <h6>Our Process</h6>
            <h2>How Do We <b>Works</b></h2>
          </div>
        </div>
      </div>
      <div class="row process-item-wrap">
        <div
          class="col-lg-3 col-md-6 col-12 wow fadeInLeft"
          data-wow-delay=".2s"
          v-for="process in choose_us.processes"
          :key="process.id"
        >
          <div class="single-process-item mb-50">
            <div class="process-num">
              <p>{{ process.id }}</p>
            </div>
            <div class="process-icon">
              <img :src="process.icon" alt="" />
            </div>
            <div class="process-content">
              <h5>{{ process.title }}</h5>
              <p>{{ process.short_description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--CTA Section-->

  <CTA />

  <!--Pricing Section -->

  <div class="pricing-section section-padding">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="section-title text-center">
            <h6>Pricing</h6>
            <h2>
              Choose Your Affordable<b><br />Package</b>
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6" v-for="price in prices" :key="price.id">
          <div class="single-price-item wow fadeInLeft" data-wow-delay=".3s">
            <h5>{{ price.package_name }}</h5>
            <div class="price-box">
              <p>
                <b>${{ price.package_price }}</b>
              </p>
              <h6>{{ price.features[0].name }}</h6>
            </div>
            <div class="price-list">
              <ul>
                <li
                  v-for="feature in price.features.slice(
                    1,
                    price.features.length
                  )"
                  :key="feature.id"
                >
                  {{ feature.name }}
                </li>
                <!-- <li>Law Planning</li>
                                <li>Legal Advice</li>
                                <li>Hire Attorney</li> -->
              </ul>
            </div>
            <div class="main-btn small-btn">Choose Plan</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Contact Section -->

  <div class="contact-section bg-cover section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12 wow fadeInLeft">
          <div class="contact-info">
            <div class="section-title">
              <h6>Contact Us</h6>
              <h2>Get in <b>Touch</b></h2>
            </div>
            <p>
              If you have a contact Lorem ipsum dolor sit amet cum maiores,
              excepturi fuga, est doloremque praesentium!
            </p>
            <div class="contact-content">
              <div class="contact-inner">
                <i class="las la-map-marker"></i>
                <h6>Visit Us</h6>
                <p>{{ contact.address }}</p>
              </div>
              <div class="contact-inner">
                <i class="las la-envelope"></i>
                <h6>Email Address</h6>
                <p>{{ contact.mail }}</p>
              </div>
              <div class="contact-inner">
                <i class="las la-phone"></i>
                <h6>Call Now</h6>
                <p>{{ contact.phone }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12 wow fadeInRight">
          <div class="contact-form">
            <form action="/">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <input type="text" placeholder="Your Name" />
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <input type="email" placeholder="E-mail" />
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <input type="tel" placeholder="Phone Number" />
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <input type="text" placeholder="Subject" />
                </div>
                <div class="col-lg-12">
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                    placeholder="Write Message"
                  ></textarea>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <button class="main-btn">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Blog Section-->

  <div class="blog-area gray-bg section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="section-title">
            <h6>Blog</h6>
            <h2>Latest Law <b>Articles</b></h2>
          </div>
        </div>

        <div class="col-lg-6 text-right"></div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft"
          data-wow-delay=".4s"
          v-for="blog in blogs"
          :key="blog.id"
        >
          <div class="single-blog-item">
            <div class="blog-bg">
              <img :src="blog.images.banner_image" alt="" />
            </div>
            <div class="blog-content">
              <p class="blog-meta">
                <i class="las la-user-circle"></i>{{ blog.author }} |
                <i class="las la-calendar-check"></i>{{ blog.date }}
              </p>
              <h5>
                <router-link
                  :to="{ name: 'BlogDetails', params: { id: blog.id } }"
                  >{{ blog.title }}</router-link
                >
              </h5>
              <p>{{ blog.short_description.substr(0, 40) }}...</p>
              <router-link
                :to="{ name: 'BlogDetails', params: { id: blog.id } }"
                class="read-more"
                >Read More</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import NavbarHome3 from "@/components/NavbarHome3.vue";
import CTA from "@/components/CTA.vue";
import Footer from "@/components/Footer.vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import axios from "axios";

export default {
  name: "Home3",
  components: { NavbarHome3, Swiper, SwiperSlide, CTA, Footer },
  data() {
    return {
      modules: [Navigation, Pagination, A11y, Autoplay],
      blogs: [],
      prices: [],
      choose_us: [],
      contact: [],
      achivement: [],
      services: [],
      allCases: [],
    };
  },

  async mounted() {
    //scrolling to top of the window
    window.scrollTo(0, 0);

    let fetchedBlogData = await axios.get("/assets/data/blogs.json");
    this.blogs = fetchedBlogData.data.blogs;

    let fetchedData = await axios.get("/assets/data/prices.json");
    this.prices = fetchedData.data.prices;

    let fetchedChooseData = await axios.get("/assets/data/choose_us.json");
    this.choose_us = fetchedChooseData.data.choose_us;

    let fetchedContactData = await axios.get("/assets/data/homeData.json");
    this.contact = fetchedContactData.data.homeData;

    let fetchedAchivementData = await axios.get("/assets/data/achivement.json");
    this.achivement = fetchedAchivementData.data.achivement;

    let fetcheServicesdData = await axios.get("/assets/data/services.json");
    this.services = fetcheServicesdData.data.services;

    let fetchedCaseData = await axios.get("/assets/data/cases.json");
    this.allCases = fetchedCaseData.data.cases;
  },
};
</script>
<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "Home 3 | Pitron | Lawyers & Law Firm Vue Js Template",
  description: "Home page | Lawyers & Law Firm Vue Js Template",
  ogDescription: "Home page | Lawyers & Law Firm Vue Js Template",
  ogTitle: "Home 3 | Pitron | Lawyers & Law Firm Vue Js Template",
});
</script>
